import { Hashtag } from '../models/Hashtag';
import { apiUrl } from '../constants';

export const getHashtags = (): Promise<Hashtag[]> => {
  return fetch(`${apiUrl}/hashtags`).then((r) => r.json() as Promise<Hashtag[]>);
};

export const addHashtag = (name: string, token: string): Promise<string> => {
  return fetch(`${apiUrl}/hashtags`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ name }),
  }).then((r) => r.json() as Promise<string>);
};
