import { DefaultButton, Panel, PanelType } from '@fluentui/react';
import React, { useState } from 'react';
import { PostContentFormModal } from '../PostContentFormModal/PostContentFormModal';
import './LocalesFrom.scss';
import { PostContentInput } from '../../../core/inputModels';

export interface LocalesFormProps {
  readonly value: PostContentInput[];
  readonly onChange: (items: PostContentInput[]) => void;
  readonly whatsNew: boolean;
}

export const LocalesForm = ({ value, onChange, whatsNew }: LocalesFormProps) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState<{ value: PostContentInput, index: number} | undefined>(undefined);

  const hasDefault = value.some((x) => !x.locale);
  const defaultAvailable = value.filter((x) => x.locale !== '').length === 0;

  const onSave = (data: PostContentInput) => {
    if (active) {
      const newValue = [...value];
      newValue.splice(active.index, 1, data);
      onChange(newValue);
    } else {
      onChange([...value, data]);
    }

    setActive(undefined);
    setOpen(false);
  };

  const onRemove = () => {
    if (!active) return;

    const newValue = [...value];
    newValue.splice(active.index, 1);
    onChange(newValue);

    setActive(undefined);
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
    setActive(undefined);
  };

  const onLocaleClick = (data: PostContentInput, index: number) => {
    setActive({ value: data, index });
    setOpen(true);
  };

  return (
    <>
      <div className="locales">
        {value.map((x, index) => (
          <DefaultButton
            key={x.locale}
            onClick={() => onLocaleClick(x, index)}
          >
            {x.locale ? x.locale : 'Default'}
          </DefaultButton>
        ))}
      </div>
      <DefaultButton
        text="New locale"
        iconProps={{ iconName: 'Add' }}
        disabled={hasDefault}
        allowDisabledFocus
        onClick={() => setOpen(true)}
      />
      <Panel
        type={PanelType.medium}
        isOpen={open}
        onDismiss={() => setOpen(false)}
        closeButtonAriaLabel="Close"
        isFooterAtBottom
      >
        <PostContentFormModal
          whatsNew={whatsNew}
          onSubmit={onSave}
          onCancel={onCancel}
          defaultAvailable={defaultAvailable}
          defaultValue={active?.value || value[0]}
          excludeLocales={value.map((x) => x.locale).filter((x) => x !== active?.value.locale)}
          canRemove={active !== undefined}
          onRemove={onRemove}
        />
      </Panel>
    </>
  );
};
