import React, { useRef, useState } from 'react';
import onDomRemove from './onDomRemove';
import {
  Field, FieldState, FieldStatus, FormField, FormValidation,
} from './types';

export const useFormValidation = (): FormValidation => {
  const fieldsRef = useRef<Map<Field, FormField>>(new Map());
  const observerRef = useRef<MutationObserver | undefined>(onDomRemove(fieldsRef));

  const [globalError, setGlobalError] = useState<string | undefined>(undefined);

  const handleSubmit = React.useCallback(
    // eslint-disable-next-line max-len
    (onSubmit: () => any, validate?: () => string | undefined) => async (e?: React.BaseSyntheticEvent): Promise<void> => {
      if (e && e.preventDefault) {
        e.preventDefault();
        e.persist();
      }

      const errorField = Array.from(fieldsRef.current)
        .find(
          (x) => x[1].getState().valid === FieldStatus.NotValid
            || x[1].getState().valid === FieldStatus.Processing,
        );

      if (!errorField) {
        const err = validate && validate();
        if (err) {
          setGlobalError(err);
          return;
        }

        onSubmit();
        return;
      }

      errorField[1].setDisplayError(true);
      errorField[0].focus();
    },
    [],
  );

  const addField = React.useCallback((
    element: Field,
    getState: () => FieldState,
    setDisplayError: (display: boolean) => void,
    onRemoved: () => void,
  ) => {
    const onDestroy = () => {
      if (fieldsRef.current.has(element)) {
        fieldsRef.current.delete(element);
        onRemoved();
      }
    };

    fieldsRef.current.set(element, { getState, onDestroy, setDisplayError });
  }, []);

  const removeField = React.useCallback(
    (element: Field) => fieldsRef.current.has(element) && fieldsRef.current.delete(element),
    [],
  );

  React.useEffect(() => {
    const observer = observerRef.current;
    const fields = fieldsRef.current;

    return () => {
      if (observer) observer.disconnect();

      fields.forEach((v) => v.onDestroy());
    };
  }, []);

  return {
    handleSubmit,
    addField,
    removeField,
    globalError,
  };
};
