import { Button } from '@chakra-ui/react';
import { Heading, HStack, VStack } from '@chakra-ui/layout';
import {
  Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Feedback } from '../../core/models/Feedback';
import { getFeedbackList } from '../../core/services/feedbackService';

const FeedbackPage = () => {
  const [feedback, setFeedback] = useState<Feedback[]>([]);
  const [page, setPage] = useState(1);

  const perPage = 20;

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getFeedbackList();
        setFeedback(res.sort((a, b) => b.timestamp.localeCompare(a.timestamp)));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    fetch();
  }, []);

  const curPage = feedback.slice((page - 1) * perPage, page * perPage);
  const maxPage = Math.trunc(feedback.length / perPage) + 1;

  return (
    <VStack spacing={5} w="100%">
      <Heading my={5}>Feedback</Heading>
      <Table variant="simple" w="100%">
        <Thead>
          <Tr>
            <Th>Device Id</Th>
            <Th>Feeback</Th>
            <Th>Timestamp</Th>
          </Tr>
        </Thead>
        <Tbody>
          {curPage.map((item) => (
            <Tr key={item.key}>
              <Td>{item.key.split(':')[0]}</Td>
              <Td>{item.feedback}</Td>
              <Td>{moment(item.timestamp).format('MMM Do YY')}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <HStack w="100%" alignItems="end" justifyContent="end">
        <Button disabled={page === 1} onClick={() => setPage(page > 1 ? page - 1 : page)}>Prev</Button>
        <Button disabled={page === maxPage} onClick={() => setPage(page + 1)}>Next</Button>
      </HStack>
    </VStack>
  );
};

export default FeedbackPage;
