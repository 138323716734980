import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line no-shadow
export enum AppApiStatus {
  Init,
  Loading,
  Loaded,
  Error
}

interface ServiceInit {
  status: AppApiStatus.Init;
}
interface ServiceLoading {
  status: AppApiStatus.Loading;
}
interface ServiceLoaded<T> {
  status: AppApiStatus.Loaded;
  payload: T;
}
interface ServiceError {
  status: AppApiStatus.Error;
  error: Error;
}
export type Service<T> =
  | ServiceInit
  | ServiceLoading
  | ServiceLoaded<T>
  | ServiceError;

export const useApi = <T>(apiFunc: () => Promise<T>) => {
  const [service, setService] = useState<Service<T>>({
    status: AppApiStatus.Init,
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setService({ status: AppApiStatus.Loading });

      try {
        const res = await apiFunc();
        setService({ status: AppApiStatus.Loaded, payload: res });
      } catch (error) {
        setService({ status: AppApiStatus.Error, error: error as any });
      }
    };
    fetchData();
  }, [apiFunc]);

  return {
    service,
  };
};

export const useApiCallback = <T>(apiFunc: (...params: any[]) => Promise<T>, fnCallback?: (value: T) => any)
  : [Service<T>, (...params: any[]) => void] => {
  const [service, setService] = useState<Service<T>>({
    status: AppApiStatus.Init,
  });

  const sendRequest = useCallback((...params: any[]) => {
    const fetchData = async (): Promise<void> => {
      setService({ status: AppApiStatus.Loading });

      try {
        const res = await apiFunc(...params);

        if (fnCallback) {
          fnCallback(res);
        }

        setService({ status: AppApiStatus.Loaded, payload: res });
      } catch (error) {
        setService({ status: AppApiStatus.Error, error: error as any });
      }
    };
    fetchData();
  }, [apiFunc, fnCallback]);

  return [
    service,
    sendRequest,
  ];
};
