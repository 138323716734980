import React from 'react';
import { Separator } from '@fluentui/react';
import { Hashtag } from '../../../core/models/Hashtag';
import './EditPostForm.scss';
import { PostContentForm } from '../PostContentForm/PostContentForm';
import PostBodyForm from '../PostBodyForm/PostBodyForm';
import { EditPostInput } from '../../../core/inputModels';

export interface EditPostFormProps {
  readonly hashtags: Hashtag[];
  readonly onChange: (data: EditPostInput) => void;
  readonly value: EditPostInput;
}

export const EditPostForm: React.FC<EditPostFormProps> = ({
  hashtags, value, onChange,
}: EditPostFormProps) => {
  return (
    <>
      <PostBodyForm
        value={value.info}
        onChange={(info) => onChange({ ...value, info })}
        hashtags={hashtags}
        locale={value.content.locale}
      />
      <Separator />
      <PostContentForm
        value={value.content}
        onChange={(content) => onChange({ ...value, content })}
        whatsNew={value.info.whatsNew}
      />
    </>
  );
};
