import React from 'react';
import {
  MessageBar, MessageBarType, PrimaryButton, Separator, TextField,
} from '@fluentui/react';
import './HashtagForm.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AppApiStatus } from '../../../core/services/api';

export interface HashtagFormProps {
  readonly onSave: (name: string) => void;
  readonly status: AppApiStatus;
}

const hashtagSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Max length 50 characters')
    .required('Required'),
});

export const HashtagForm = ({ onSave, status }: HashtagFormProps) => {
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    isInitialValid: false,
    validationSchema: hashtagSchema,
    onSubmit: (values) => onSave(values.name),
  });

  return (
    <div className="hashtag-form">
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="name"
          aria-labelledby="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          errorMessage={formik.errors.name}
        />
        <Separator />
        <PrimaryButton text="Save" disabled={!formik.isValid || status === AppApiStatus.Loading} type="submit" />
      </form>
      <div className="message-bar">
        {status === AppApiStatus.Loading && (
          <MessageBar messageBarType={MessageBarType.info}>
            Saving...
          </MessageBar>
        )}
        {status === AppApiStatus.Loaded && (
          <MessageBar messageBarType={MessageBarType.success}>
            Saved
          </MessageBar>
        )}
        {status === AppApiStatus.Error && (
          <MessageBar messageBarType={MessageBarType.error}>
            Something get wrong.
          </MessageBar>
        )}
      </div>
    </div>
  );
};
