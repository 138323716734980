import React from 'react';
import { Separator } from '@fluentui/react';
import { Hashtag } from '../../../core/models/Hashtag';
import './AddPostForm.scss';
import { LocalesForm } from '../LocalesForm/LocalesForm';
import { Controller } from '../../../core/validation/controller';
import PostBodyForm from '../PostBodyForm/PostBodyForm';
import { AddPostInput, PostContentInput, PostInfoInput } from '../../../core/inputModels';

export interface AddPostFormProps {
  readonly hashtags: Hashtag[];
  readonly onChange: (data: AddPostInput) => void;
  readonly value: AddPostInput;
}

export const AddPostForm: React.FunctionComponent<AddPostFormProps> = ({
  hashtags, onChange, value,
}: AddPostFormProps) => {
  const onInfoChange = React.useCallback((input: PostInfoInput) => {
    if (input.whatsNew !== value.info.whatsNew && !input.whatsNew) {
      onChange({
        ...value,
        info: input,
        localizedContent: value.localizedContent.map((x) => ({ ...x, whatsNewContent: { hashtag: '' } })),
      });
    } else {
      onChange({ ...value, info: input });
    }
  }, [onChange, value]);

  return (
    <>
      <PostBodyForm locale="en" value={value.info} onChange={onInfoChange} hashtags={hashtags} />
      <Separator />
      <Controller<PostContentInput[]>
        value={value.localizedContent ?? []}
        rules={{ required: 'Please add locales' }}
        changeValue={(localizedContent) => onChange({ ...value, localizedContent })}
        render={({ onChangeValue, error }) => (
          <>
            <LocalesForm value={value.localizedContent ?? []} onChange={onChangeValue} whatsNew={value.info.whatsNew} />
            {error && (
            <p className="ms-TextField-errorMessage errorMessage-157">
              <span>{error}</span>
            </p>
            )}
          </>
        )}
      />
    </>
  );
};
