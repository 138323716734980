import { MediaItemDto } from '../media/MediaItemDto';
import { ImageDto } from '../media/ImageDto';
import { ImageMediaItemDto } from '../media/ImageMediaItemDto';
import { ClickableItem } from '../../models/ClickableItem';

export interface CreatePostDto {
  readonly slug: string;
  readonly meta: MetaInformationDto;
  readonly hashtags?: string[];
  readonly localizedContent: LocalizedItem<LocalizedContentDto>[];
  readonly pinned: boolean;
  readonly version: number;
  readonly author: {
    readonly name: string;
    readonly link: string;
    readonly image?:ImageDto;
  } | null;
}

// eslint-disable-next-line no-shadow
export enum PostFeature {
  InPainting = 0,
  Filters = 1,
  Feed = 2,
  Pro = 3,
  NotPro = 4,
}

export interface MetaInformationDto {
  readonly abTest?: string;
  readonly minIosVersion: string;
  readonly maxIosVersion?: string;
  readonly minAndroidVersion: string;
  readonly maxAndroidVersion?: string;
  readonly features: PostFeature[];
}

export interface LocalizedContentDto {
  readonly attachments: MediaItemDto[];
  readonly content: PostContentDto;
  readonly stories: string[];
  readonly whatsNewContent: WhatsNewContentDto;
  readonly preview?: ImageMediaItemDto;
  readonly clickableItems?: ClickableItem[];
}

export interface WhatsNewContentDto {
  readonly hashtag: string;
}

export interface PostContentDto {
  readonly title: string;
  readonly subtitle: string;
}

export interface LocalizedItem<TItem> {
  readonly locale: string;
  readonly item: TItem;
}
