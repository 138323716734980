import { VideoDto } from '../dtos/media/VideoDto';

export interface VideoMediaItem {
  readonly type: 'video_media';
  readonly video: Video;
}

export interface Video {
  readonly videoUrl: string;
}

export const toVideoDto = (video?: Video): VideoDto | undefined => {
  if (!video) {
    return undefined;
  }

  return {
    type: 'video_media',
    ...video,
  };
};
