import React, {
  PropsWithChildren, useContext, useEffect, useState,
} from 'react';
import { Auth } from '@aws-amplify/auth';

export interface AuthProviderValue {
  readonly isLoading: boolean;
  readonly isAuthorized: boolean;
  readonly user: any | null;
  readonly accessToken: string;
}

const emptyValue: AuthProviderValue = {
  user: null,
  isLoading: false,
  isAuthorized: false,
  accessToken: '',
};

const AuthContext = React.createContext(emptyValue);

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any | null>(null);

  useEffect(() => {
    const checkUser = async () => {
      setLoading(true);
      try {
        const res = await Auth.currentAuthenticatedUser();
        setUser(res);
      } catch {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkUser();
  }, []);

  return (
    <AuthContext.Provider
      value={
        {
          isLoading,
          user,
          isAuthorized: user !== null,
          accessToken: user?.signInUserSession?.accessToken.jwtToken ?? '',
        }
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthProvider(): AuthProviderValue {
  return useContext(AuthContext);
}
