import { TextField, ITextFieldProps } from '@fluentui/react';
import React, { FormEvent } from 'react';
import { Controller } from '../../../core/validation/controller';
import { ValidationRules } from '../../../core/validation/types';

export interface TextInputProps extends ITextFieldProps {
  readonly rules?: ValidationRules;
}

export const TextInput = React.memo(({
  rules, onChange, ...rest
}: TextInputProps) => {
  return (
    <Controller<string | undefined>
      value={rest.value}
      rules={rules}
      changeValue={(v) => { if (onChange) onChange({} as FormEvent<HTMLInputElement>, v); }}
      render={({ onChangeValue, onBlur, error }) => (
        <TextField
          {...rest}
          onChange={(e, v) => onChangeValue(v)}
          onBlur={() => onBlur()}
          errorMessage={error}
        />
      )}
    />
  );
});
