import React, { useState } from 'react';
import { DefaultButton, PrimaryButton, Separator } from '@fluentui/react';
import './PostContentFormModal.scss';
import { useFormValidation } from '../../../core/validation/useFormValidation';
import { FormValidationProvider } from '../../../core/validation/useFormValidationContext';
import { PostContentForm } from '../PostContentForm/PostContentForm';
import { defaultPostContentInput, PostContentInput } from '../../../core/inputModels';

const buttonStyles = { root: { marginRight: 8 } };

export interface PostContentFormPros {
  readonly onSubmit: (value: PostContentInput) => void;
  readonly onCancel: () => void;
  readonly defaultAvailable?: boolean;
  readonly defaultValue?: PostContentInput;
  readonly excludeLocales?: string[];
  readonly canRemove?: boolean;
  readonly onRemove?: () => void;
  readonly whatsNew: boolean;
}

export const PostContentFormModal = ({
  onSubmit,
  defaultAvailable,
  onCancel,
  excludeLocales,
  canRemove,
  onRemove,
  defaultValue,
  whatsNew,
}: PostContentFormPros) => {
  const methods = useFormValidation();
  const [value, setValue] = useState<PostContentInput>(defaultValue ?? defaultPostContentInput);

  const { handleSubmit } = methods;

  return (
    <FormValidationProvider {...methods}>
      <PostContentForm
        value={value}
        onChange={setValue}
        whatsNew={whatsNew}
        defaultAvailable={defaultAvailable}
        excludeLocales={excludeLocales}
      />
      <Separator />
      <div className="buttons">
        <div className="left-buttons">
          <PrimaryButton
            onClick={handleSubmit(() => onSubmit(value))}
            styles={buttonStyles}
          >
            Save
          </PrimaryButton>
          <DefaultButton onClick={onCancel} styles={buttonStyles}>Cancel</DefaultButton>
        </div>
        {canRemove
      && (
        <div className="right-buttons">
          <DefaultButton
            iconProps={{ iconName: 'Delete' }}
            onClick={onRemove}
            styles={buttonStyles}
          >
            Remove
          </DefaultButton>
        </div>
      )}
      </div>
    </FormValidationProvider>
  );
};
