import React, { PropsWithChildren } from 'react';
import './Layout.scss';
import { Stack, IStackTokens, IStackItemStyles } from '@fluentui/react';
import { AppNav } from '../AppNav/AppNav';
import { Header } from '../Header/Header';

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
};

export const Layout: React.FunctionComponent = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div className="container">
      <Header />
      <Stack className="layout" horizontal tokens={stackTokens}>
        <Stack.Item grow={0} styles={stackItemStyles}>
          <AppNav />
        </Stack.Item>
        <Stack.Item grow={1} className="content" styles={stackItemStyles}>
          {children}
        </Stack.Item>
      </Stack>
      <div style={{ margin: '0 2rem' }}>
        <p>© 2020 Vochi </p>
      </div>
    </div>
  );
};
