import React, { useRef, useState } from 'react';
import { getInputValue } from './getInputValue';
import {
  FieldState, FieldStatus, FormValidation, ValidationRules,
} from './types';

export const validate = async (
  value: any,
  rules?: ValidationRules,
): Promise<string | undefined> => {
  const { required, pattern, validate: validateRule } = rules || {};

  if (required) {
    if (!value || (Array.isArray(value) && !value.length)) return required;
  }

  if (pattern) {
    if (value && !pattern.reg.test(value)) {
      return pattern.message;
    }
  }

  if (validateRule) {
    return validateRule(value);
  }

  return undefined;
};

export const useValidation = ({ addField, removeField }: Partial<FormValidation>):
[(rules?: ValidationRules) => (ref: HTMLInputElement | null) => void, any] => {
  const [errors, setErrors] = useState<{[key: string]: FieldState}>({});
  const errorsRef = useRef(errors);
  errorsRef.current = errors;

  const fieldsRef = useRef<{[key: string]: { ref: HTMLInputElement, rules: ValidationRules | undefined }}>({});

  const validateValue = async (field: HTMLInputElement, showError: boolean) => {
    const { name } = field;

    setErrors({
      ...errors,
      [name]: {
        valid: FieldStatus.Processing,
        showError,
      },
    });

    const error = await validate(getInputValue(field), fieldsRef.current[name].rules);
    setErrors({
      ...errors,
      [name]: {
        valid: error ? FieldStatus.NotValid : FieldStatus.Valid,
        error,
        showError,
      },
    });
  };

  const handleEventRef = useRef(async ({ type, target }: Event) => {
    const ref = (target as HTMLInputElement)!;

    if (type === 'input' || type === 'blur') {
      await validateValue(ref, true);
    }
  });

  const unregister = React.useCallback((ref: HTMLInputElement) => {
    const fields = fieldsRef.current;
    const { name } = ref;

    delete fields[name];
    ref.removeEventListener('input', handleEventRef.current);
    ref.removeEventListener('blur', handleEventRef.current);
  }, []);

  const register = React.useCallback((rules?: ValidationRules) => (ref: HTMLInputElement | null) => {
    if (!ref) return;

    const fields = fieldsRef.current;
    const { name } = ref;

    if (fields[name] && fields[name].ref === ref) {
      fields[name].rules = rules;

      return;
    }

    fields[name] = {
      ref,
      rules,
    };

    ref.addEventListener('input', handleEventRef.current);
    ref.addEventListener('blur', handleEventRef.current);

    if (addField) {
      addField(
        ref,
        () => errorsRef.current[name],
        (display: boolean) => setErrors({ ...errors, [name]: { ...errors[name], showError: display } }),
        () => unregister(ref),
      );
    }
    validateValue(ref, false);
  }, [addField, unregister]);

  React.useEffect(() => {
    const fields = fieldsRef.current;

    return () => {
      Object.values(fields).forEach((x) => {
        unregister(x.ref);
        if (removeField) removeField(x.ref);
      });
    };
  }, []);

  return [register, errors];
};
