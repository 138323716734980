import React from 'react';
import { ActionButton, Separator } from '@fluentui/react';
import { MediaUploader } from '../../other/MediaUploader/MediaUploader';
import { MediaDto } from '../../../core/dtos/media/MediaDto';
import './StoryInput.scss';
import { ClickableItem } from '../../../core/models/ClickableItem';
import { ImageDto } from '../../../core/dtos/media/ImageDto';
import { VideoDto } from '../../../core/dtos/media/VideoDto';
import { Dimension } from '../../../core/models/Story';
import { Controller } from '../../../core/validation/controller';

export interface StoryInputModel {
  readonly id: string;
  readonly type: string;
  readonly image?: ImageDto;
  readonly video?: VideoDto;
  readonly clickableItems?: ClickableItem[];
  readonly dimension: Dimension;
}

export interface StoryInputProps {
  readonly locale: string;
  readonly value: StoryInputModel;
  readonly onChange: (value: Partial<StoryInputModel>) => void;
  readonly onRemove: () => void;
}

export const StoryInput = ({
  locale, value, onChange, onRemove,
}: StoryInputProps) => {
  const onMediaChange = (
    data: {
      media: MediaDto,
      dimension: { readonly width: number, readonly height: number }
    } | undefined,
  ) => {
    if (!data) {
      onChange({
        ...value,
        video: undefined,
        image: undefined,
        dimension: undefined,
        clickableItems: value.clickableItems?.filter((x) => x.type !== 'button_component'),
      });
      return;
    }

    if (data.media.type === 'image_media') {
      onChange({
        ...value, image: data.media, video: undefined, type: 'image_media', dimension: data.dimension,
      } as StoryInputModel);
    } else {
      onChange({
        ...value, image: undefined, video: data.media, type: 'video_media', dimension: data.dimension,
      } as StoryInputModel);
    }
  };

  return (
    <div className="story-input">
      <div className="story-input-media">
        <Controller<{ media: MediaDto, dimension: { width: number, height: number } } | undefined>
          value={(value.image || value.video)
          && { media: value.image || value.video!, dimension: value.dimension }}
          changeValue={onMediaChange}
          rules={{ required: 'Please load image or video' }}
          render={({ onChangeValue, error }) => (
            <MediaUploader
              previewWidth="170px"
              changeValue={onChangeValue}
              locale={locale}
              value={(value.image || value.video)
              && { media: value.image || value.video!, dimension: value.dimension }}
              errorMessage={error}
            />
          )}
        />
      </div>
      <div className="story-input-clickable">
        <ActionButton iconProps={{ iconName: 'Trash' }} onClick={onRemove}>Remove</ActionButton>
        <Separator />
      </div>
    </div>
  );
};
