import { isImage } from '../helper';
import { apiUrl } from '../constants';

export const imageUpload = (locale: string, image: File, token: string) => {
  const formData = new FormData();

  formData.append(
    'file',
    image,
  );

  return fetch(`${apiUrl}/media/${locale}/image`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  }).then((r) => r.json() as Promise<string>);
};

export const fileUpload = (locale: string, media: File, token: string) => {
  const formData = new FormData();

  formData.append(
    'file',
    media,
  );

  return fetch(`${apiUrl}/media/${locale}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  }).then((r) => r.json() as Promise<string>);
};

export const uploadMedia = (locale: string, media: File, token: string) => {
  if (isImage(media.name)) {
    return imageUpload(locale, media, token);
  }

  return fileUpload(locale, media, token);
};
