export default function isDetached(element: Element): boolean {
  if (!element) {
    return true;
  }

  if (
    !(element instanceof HTMLElement)
    || element.nodeType === Node.DOCUMENT_NODE
  ) {
    return false;
  }

  return isDetached(element.parentNode as Element);
}
