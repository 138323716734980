import { ActionButton } from '@fluentui/react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { StoryInput, StoryInputModel } from '../StoryInput/StoryInput';
import './MultiStoriesInput.scss';

export interface MultiStoriesInputProps {
  readonly locale: string;
  readonly value: StoryInputModel[];
  readonly onChange: (value: StoryInputModel[]) => void;
}

export const MultiStoriesInput = ({ value, locale, onChange }: MultiStoriesInputProps) => {
  const onStoryChange = (data: StoryInputModel, index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1, data);
    onChange(newValue);
  };

  const onRemove = (index: number) => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  return (
    <div className="multi-stories">
      {value.map((x, index) => (
        <StoryInput
          key={x.id}
          onRemove={() => onRemove(index)}
          locale={locale}
          value={x}
          onChange={(v) => onStoryChange(v as StoryInputModel, index)}
        />
      ))}
      <ActionButton
        iconProps={{ iconName: 'Add' }}
        onClick={() => onChange([...value, { id: uuidv4(), type: '', dimension: { width: 0, height: 0 } }])}
      >
        Add story
      </ActionButton>
    </div>
  );
};
