import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  IBreadcrumbItem,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Spinner,
} from '@fluentui/react';
import { AppApiStatus, Service, useApi } from '../../../core/services/api';
import { getHashtags } from '../../../core/services/hashtagService';
import { addPost, getPost, updatePost } from '../../../core/services/postsService';
import { EditPostForm } from '../../../features/posts/EditPostForm/EditPostForm';
import { Post } from '../../../core/models/Post';
import { mapMedia } from '../../../core/models/MediaItem';
import { toImageDto } from '../../../core/models/Image';
import { toVideoDto } from '../../../core/models/Video';
import { UpdatePostDto } from '../../../core/dtos/posts/UpdatePostDto';
import { addBulkStories, updateStory } from '../../../core/services/storiesService';
import { useAuthProvider } from '../../../providers/authProvider';
import { CreatePostDto } from '../../../core/dtos/posts/CreatePostDto';
import { EditPostInput } from '../../../core/inputModels';
import { useFormValidation } from '../../../core/validation/useFormValidation';
import { FormValidationProvider } from '../../../core/validation/useFormValidationContext';
import './EditPost.scss';

export const PostEditRoute = '/posts/edit';

export const EditPost = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { accessToken } = useAuthProvider();

  const methods = useFormValidation();

  const {
    handleSubmit,
    globalError,
  } = methods;

  const [value, setValue] = React.useState<EditPostInput | null>(null);

  const { service } = useApi(getHashtags);

  const [postService, setPostService] = useState<Service<Post>>({
    status: AppApiStatus.Init,
  });
  const [updatePostService, setUpdatePostService] = useState<Service<undefined>>({
    status: AppApiStatus.Init,
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setPostService({ status: AppApiStatus.Loading });

      try {
        const res = await getPost(params.id);
        setValue(mapToFormModel(res));
        setPostService({ status: AppApiStatus.Loaded, payload: res });
      } catch (error) {
        setPostService({ status: AppApiStatus.Error, error });
      }
    };
    fetchData();
  }, [params.id]);

  const sendRequest = useCallback((data: EditPostInput, newLocale?: boolean) => {
    const update = async (): Promise<void> => {
      if (postService.status === AppApiStatus.Loaded) {
        setUpdatePostService({ status: AppApiStatus.Loading });

        const storiesToAdd = data.content.stories
          .filter((x) => !postService.payload.stories.some((s) => s.id === x.id));
        const storiesToUpdate = data.content.stories
          .filter((x) => postService.payload.stories.some((s) => s.id === x.id));

        let added: string[] = [];
        if (storiesToAdd.length) added = await addBulkStories({ stories: storiesToAdd }, accessToken);

        await Promise.all(storiesToUpdate.map((x) => updateStory(x, accessToken)));

        try {
          const model: UpdatePostDto = {
            ...data.info,
            ...data.content,
            id: postService.payload.id,
            status: postService.payload.status,
            stories: postService.payload.stories
              .map((x) => x.id)
              .filter((x) => data.content.stories.some((s) => s.id === x)).concat(added),
          };

          await updatePost(model, accessToken);

          history.push('/posts');
          setUpdatePostService({ status: AppApiStatus.Loaded, payload: undefined });
        } catch (error) {
          setUpdatePostService({ status: AppApiStatus.Error, error });
        }
      }
    };

    const addLocale = async (): Promise<void> => {
      if (postService.status === AppApiStatus.Loaded) {
        setUpdatePostService({ status: AppApiStatus.Loading });

        try {
          let stories: string[] = [];

          if (data.content.stories.length) {
            stories = await addBulkStories({ stories: data.content.stories }, accessToken);
          }

          await addPost({
            ...data.info,
            localizedContent: [{ locale: data.content.locale, item: { ...data.content, stories } }],
          } as CreatePostDto, accessToken);

          history.push('/posts');
          setUpdatePostService({ status: AppApiStatus.Loaded, payload: undefined });
        } catch (error) {
          setUpdatePostService({ status: AppApiStatus.Error, error });
        }
      }
    };

    if (newLocale === true) {
      addLocale();
    } else {
      update();
    }
  }, [accessToken, history, postService]);

  const mapToFormModel = (post: Post): EditPostInput => {
    return {
      info: {
        version: post.version,
        slug: post.slug,
        meta: post.meta,
        hashtags: post.hashtags,
        pinned: post.pinned,
        whatsNew: !!post.whatsNewContent.hashtag,
        author: post.author ? {
          ...post.author,
          image: toImageDto(post.author.image),
        } : undefined,
      },
      content: {
        preview: post.preview,
        whatsNewContent: post.whatsNewContent,
        content: post.content,
        locale: post.meta.locale,
        attachments: post.attachments.map(mapMedia),
        stories: post.stories.map((x) => (
          {
            ...x,
            image: toImageDto(x.image),
            video: toVideoDto(x.video),
          })),
        clickableItems: post.clickableItems,
      },
    };
  };

  const items: IBreadcrumbItem[] = [
    {
      text: 'Posts',
      key: '/posts',
      onClick: (ev, item) => {
        if (item && item.key) {
          history.push(item.key);
        }
      },
    },
    { text: 'Edit', key: PostEditRoute, isCurrentItem: true },
  ];

  const loading = updatePostService.status === AppApiStatus.Loading;

  return (
    <FormValidationProvider {...methods}>
      <div className="posts-page">
        <Breadcrumb
          items={items}
          maxDisplayedItems={5}
        />
        {service.status === AppApiStatus.Error && (
          <MessageBar
            className="error-message"
            messageBarType={MessageBarType.error}
            isMultiline={false}
          >
            {service.error.message || 'Something get wrong'}
          </MessageBar>
        )}
        {service.status === AppApiStatus.Loaded
          && value !== null
          && postService.status === AppApiStatus.Loaded
          && (
            <div className="edit-post-form">
              <EditPostForm
                value={value}
                hashtags={service.payload}
                onChange={setValue}
              />
              <Separator />
              {postService.payload.meta.locale !== value.content.locale
                ? (
                  <PrimaryButton
                    type="button"
                    onClick={handleSubmit(() => sendRequest(value, true))}
                    disabled={loading}
                  >
                    Save as new locale {loading && <Spinner />}
                  </PrimaryButton>
                )
                : (
                  <PrimaryButton
                    type="button"
                    onClick={handleSubmit(() => sendRequest(value))}
                    disabled={loading}
                  >
                    Save {loading && <Spinner />}
                  </PrimaryButton>
                )}
            </div>
          )}
        {(postService.status === AppApiStatus.Error || globalError) && (
          <MessageBar
            className="error-message"
            messageBarType={MessageBarType.error}
            isMultiline={false}
          >
            {globalError || 'Something get wrong'}
          </MessageBar>
        )}
      </div>
    </FormValidationProvider>
  );
};
