import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import { vochiStatic } from '../../../core/constants';
import { ImageDto } from '../../../core/dtos/media/ImageDto';
import { AppApiStatus, useApiCallback } from '../../../core/services/api';
import { uploadMedia } from '../../../core/services/mediaService';
import { useAuthProvider } from '../../../providers/authProvider';

export interface UploadButtonProps {
  readonly value?: ImageDto;
  readonly onChange: (newValue: ImageDto) => void;
  readonly locale?: string;
}

export default function UploadButton({
  onChange,
  children,
  value,
  locale = 'default',
}: React.PropsWithChildren<UploadButtonProps>) {
  const { accessToken } = useAuthProvider();

  const onUploaded = (url: string) => {
    onChange({ imageUrl: url, type: 'image_media' });
  };

  const [service, sendRequest] = useApiCallback<string>(uploadMedia, onUploaded);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length) {
      sendRequest(locale, e.target.files[0], accessToken);
    }
  };

  if (service.status === AppApiStatus.Loading) {
    return (
      <Spinner label="I am definitely loading..." size={SpinnerSize.large} style={{ minHeight: 300 }} />
    );
  }

  if (value) {
    const url = `${vochiStatic}/${value.imageUrl}`;

    return <img src={url} width="335px" alt="preview" />;
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <input
        id="image-upload"
        accept="image/*"
        style={{ display: 'none' }}
        type="file"
        onChange={handleChange}
      />
      <span style={{ cursor: 'pointer' }}>
        {children}
      </span>
    </label>
  );
}
