import { CreateStoryBulkDto } from '../dtos/stories/CreateStoryBulkDto';
import { apiUrl } from '../constants';
import { UpdateStoryDto } from '../dtos/stories/UpdateStoryDto';

export const addBulkStories = (model: CreateStoryBulkDto, token: string): Promise<string[]> => {
  return fetch(`${apiUrl}/stories/bulk`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(model),
  }).then((r) => r.json() as Promise<string[]>);
};

export const updateStory = async (model: UpdateStoryDto, token: string): Promise<void> => {
  await fetch(`${apiUrl}/stories`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(model),
  });
};
