import React from 'react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { HashtagForm } from '../../../features/hashtags/HashtagForm/HashtagForm';
import './AddHashtag.scss';
import { addHashtag } from '../../../core/services/hashtagService';
import { useApiCallback } from '../../../core/services/api';
import { useAuthProvider } from '../../../providers/authProvider';

export const HashtagAddRoute = '/hashtags/add';

export const AddHashtag = () => {
  const history = useHistory();
  const { accessToken } = useAuthProvider();

  const items: IBreadcrumbItem[] = [
    {
      text: 'Hashtags',
      key: '/hashtags',
      onClick: (ev, item) => {
        if (item && item.key) {
          history.push(item.key);
        }
      },
    },
    { text: 'Add', key: HashtagAddRoute, isCurrentItem: true },
  ];

  const [service, sendRequest] = useApiCallback<string>(addHashtag, () => history.push('/hashtags'));
  return (
    <div className="hashtag-add-page">
      <Breadcrumb
        items={items}
        maxDisplayedItems={5}
      />
      <HashtagForm onSave={(name) => sendRequest(name, accessToken)} status={service.status} />
    </div>
  );
};
