import React, { useCallback, useState } from 'react';
import {
  Breadcrumb,
  IBreadcrumbItem,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Spinner,
} from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { AddPostForm } from '../../../features/posts/AddPostForm/AddPostForm';
import { AppApiStatus, Service, useApi } from '../../../core/services/api';
import { getHashtags } from '../../../core/services/hashtagService';
import { CreatePostDto, LocalizedContentDto, LocalizedItem } from '../../../core/dtos/posts/CreatePostDto';
import { addPost } from '../../../core/services/postsService';
import { addBulkStories } from '../../../core/services/storiesService';
import { useAuthProvider } from '../../../providers/authProvider';
import { AddPostInput, defaultAddPostInput, PostContentInput } from '../../../core/inputModels';
import { useFormValidation } from '../../../core/validation/useFormValidation';
import { FormValidationProvider } from '../../../core/validation/useFormValidationContext';

export const PostAddRoute = '/posts/add';

export default function AddPost() {
  const history = useHistory();
  const methods = useFormValidation();
  const [value, setValue] = useState<AddPostInput>(defaultAddPostInput);

  const { service } = useApi(getHashtags);
  const { accessToken } = useAuthProvider();

  const {
    handleSubmit,
    globalError,
  } = methods;

  const [postService, setPostService] = useState<Service<string>>({
    status: AppApiStatus.Init,
  });

  const items: IBreadcrumbItem[] = [
    {
      text: 'Posts',
      key: '/posts',
      onClick: (ev, item) => {
        if (item && item.key) {
          history.push(item.key);
        }
      },
    },
    { text: 'Add', key: PostAddRoute, isCurrentItem: true },
  ];

  const sendRequest = useCallback(async (data: AddPostInput) => {
    const postData = async (): Promise<void> => {
      setPostService({ status: AppApiStatus.Loading });

      try {
        const promises: Promise<LocalizedItem<LocalizedContentDto>>[] = data.localizedContent.map((x) => {
          if (x.stories.length) {
            return addBulkStories({ stories: x.stories }, accessToken)
              .then((res) => ({ locale: x.locale, item: { ...x, stories: res } }));
          }

          return Promise.resolve({ locale: x.locale, item: { ...x, stories: [] } });
        });

        const locales = await Promise.all(promises);

        const res = await addPost({ ...data.info, localizedContent: locales } as CreatePostDto, accessToken);
        history.push('/posts');

        setPostService({ status: AppApiStatus.Loaded, payload: res });
      } catch (error) {
        setPostService({ status: AppApiStatus.Error, error });
      }
    };

    await postData();
  }, [accessToken, history]);

  const validateWhatsNew = React.useCallback((x: PostContentInput[]) => {
    const item = x?.find((i) => !i.whatsNewContent.hashtag);

    return value.info.whatsNew && item
      ? `${item.locale === '' ? 'Default' : item.locale} locale has empty what's new tag`
      : undefined;
  }, [value.info.whatsNew]);

  return (
    <FormValidationProvider {...methods}>
      <div className="posts-page">
        <Breadcrumb
          items={items}
          maxDisplayedItems={5}
        />
        {service.status === AppApiStatus.Error && (
          <MessageBar
            className="error-message"
            messageBarType={MessageBarType.error}
            isMultiline={false}
          >
            {service.error.message || 'Something get wrong'}
          </MessageBar>
        )}
        {service.status === AppApiStatus.Loaded
          && (
            <form
              className="post-form"
              onSubmit={handleSubmit(() => sendRequest(value), () => validateWhatsNew(value.localizedContent))}
            >
              <AddPostForm
                hashtags={service.payload}
                onChange={setValue}
                value={value}
              />
              <Separator />
              <div>
                <PrimaryButton type="submit" disabled={postService.status === AppApiStatus.Loading}>
                  Save {postService.status === AppApiStatus.Loading && <Spinner />}
                </PrimaryButton>
              </div>
            </form>
          )}
        {(postService.status === AppApiStatus.Error || globalError) && (
          <MessageBar
            className="error-message"
            messageBarType={MessageBarType.error}
            isMultiline={false}
          >
            {globalError || 'Something get wrong'}
          </MessageBar>
        )}
      </div>
    </FormValidationProvider>
  );
}
