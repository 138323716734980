import { StoryInputModel } from '../features/stories/StoryInput/StoryInput';
import { currentPostVersion } from './constants';
import { ImageDto } from './dtos/media/ImageDto';
import { ImageMediaItemDto } from './dtos/media/ImageMediaItemDto';
import { MediaItemDto } from './dtos/media/MediaItemDto';
import { PostFeature } from './dtos/posts/CreatePostDto';
import { ClickableItem } from './models/ClickableItem';

export interface PostMetaInput {
  readonly abTest: string;
  readonly minIosVersion: string;
  readonly maxIosVersion: string;
  readonly minAndroidVersion: string;
  readonly maxAndroidVersion: string;
  readonly features: PostFeature[];
}

export interface PostInfoInput {
  readonly version: number;
  readonly slug: string;
  readonly meta: PostMetaInput;
  readonly hashtags?: string[];
  readonly pinned: boolean;
  readonly author?: {
    readonly name: string;
    readonly link: string;
    readonly image?: ImageDto;
  }
  readonly whatsNew: boolean;
}

export interface PostContentInput {
  readonly locale: string;
  readonly attachments: MediaItemDto[];
  readonly content: {
    readonly title: string;
    readonly subtitle: string;
  };
  readonly stories: StoryInputModel[];
  readonly whatsNewContent: {
    readonly hashtag: string;
  };
  readonly preview?: ImageMediaItemDto;
  readonly clickableItems?: ClickableItem[];
}

export interface AddPostInput {
  readonly info: PostInfoInput;
  readonly localizedContent: PostContentInput[];
}

export interface EditPostInput {
  readonly info: PostInfoInput;
  readonly content: PostContentInput;
}

// defaults

export const defaultPostMetaInput : PostMetaInput = {
  abTest: '',
  minIosVersion: '',
  maxIosVersion: '',
  minAndroidVersion: '',
  maxAndroidVersion: '',
  features: [],
};

export const defaultPostInfoInput : PostInfoInput = {
  slug: '',
  meta: defaultPostMetaInput,
  hashtags: [],
  pinned: false,
  whatsNew: false,
  version: currentPostVersion,
};

export const defaultPostContentInput: PostContentInput = {
  locale: '',
  content: {
    title: '',
    subtitle: '',
  },
  stories: [],
  attachments: [],
  whatsNewContent: {
    hashtag: '',
  },
};

export const defaultAddPostInput = {
  info: defaultPostInfoInput,
  localizedContent: [],
};

export const defaultEditPostInput = {
  info: defaultPostInfoInput,
};
