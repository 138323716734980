import { ImageDto } from '../dtos/media/ImageDto';

export interface ImageMediaItem {
  readonly type: 'image_media';
  readonly image: Image;
}

export interface Image {
  readonly imageUrl: string;
}

export const toImageDto = (image?: Image): ImageDto | undefined => {
  if (!image) {
    return undefined;
  }

  return {
    type: 'image_media',
    ...image,
  };
};
