import React from 'react';
import './MediaUploader.scss';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { MediaUploadButton } from '../MediaUploadButton/MediaUploadButton';
import { MediaPreview } from '../MediaPreview/MediaPreview';
import { AppApiStatus, useApiCallback } from '../../../core/services/api';
import { uploadMedia } from '../../../core/services/mediaService';
import { vochiStatic } from '../../../core/constants';
import { isImage } from '../../../core/helper';
import { MediaDto } from '../../../core/dtos/media/MediaDto';
import { ImageDto } from '../../../core/dtos/media/ImageDto';
import { VideoDto } from '../../../core/dtos/media/VideoDto';
import { useAuthProvider } from '../../../providers/authProvider';

export interface MediaUploaderProps {
  readonly changeValue: (value: { media: MediaDto, dimension: { width: number, height: number } } | undefined) => void;
  readonly locale: string;
  readonly value: {
    media: MediaDto,
    dimension: { width: number, height: number }
  } | undefined;
  readonly errorMessage?: string;
  readonly previewWidth?: string;
}

export const MediaUploader = ({
  value, locale, changeValue, errorMessage, previewWidth,
}: MediaUploaderProps) => {
  const { accessToken } = useAuthProvider();

  const onClose = () => {
    changeValue(undefined);
  };

  const onUploaded = (url: string) => {
    const media = isImage(url)
      ? { imageUrl: url, type: 'image_media' } as ImageDto
      : { videoUrl: url, type: 'video_media' } as VideoDto;

    changeValue(value ? { ...value, media } : { media, dimension: { width: -1, height: -1 } });
  };

  const [service, sendRequest] = useApiCallback<string>(uploadMedia, onUploaded);

  // eslint-disable-next-line no-nested-ternary
  let preview;
  if (value && value.media) {
    preview = value.media.type === 'image_media' ? value.media.imageUrl : value.media.videoUrl;
  }

  const url = `${vochiStatic}/${preview}`;

  const onChange = (file: File) => {
    if (file.size / 1024 / 1024 > 2) {
      alert(`The file size is too large ${(file.size / 1024 / 1024).toFixed(2)}, 
        try to compress it, or if this is a story then split it into several.`);
    }
    sendRequest(locale || 'default', file, accessToken);
  };

  if (service.status === AppApiStatus.Loading) {
    return (
      <Spinner label="I am definitely loading..." size={SpinnerSize.large} style={{ minHeight: 300 }} />
    );
  }

  return (
    <>
      {preview
        ? (
          <MediaPreview
            previewUrl={url}
            onClose={onClose}
            width={previewWidth}
            changeDimensions={
              (width, height) => changeValue(value ? { ...value, dimension: { width, height } } : undefined)
            }
          />
        )
        : <MediaUploadButton changeValue={onChange} />}
      {(service.status === AppApiStatus.Error || errorMessage)
        && (
          <p className="ms-TextField-errorMessage errorMessage-157">
            <span>{errorMessage || 'Something get wrong'}</span>
          </p>
        )}
    </>
  );
};
