import React, { useState } from 'react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { vochiStatic } from '../../core/constants';
import { AppApiStatus, useApiCallback } from '../../core/services/api';
import { fileUpload } from '../../core/services/mediaService';
import { useAuthProvider } from '../../providers/authProvider';

const items: IBreadcrumbItem[] = [
  { text: 'Media uploader', key: '/uploader', isCurrentItem: true },
];

const Uploader = () => {
  const { accessToken } = useAuthProvider();

  const [url, setUrl] = useState<string>('');
  const [service, sendRequest] = useApiCallback<string>(fileUpload, setUrl);

  const fullUrl = `${vochiStatic}/${url}`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length) {
      sendRequest(
        'default',
        e.target.files[0],
        accessToken,
      );
    }
  };

  return (
    <div className="hashtags-page">
      <Breadcrumb
        items={items}
        maxDisplayedItems={5}
      />
      <input type="file" onChange={handleChange} />
      <p>{service.status === AppApiStatus.Loading && 'Loading...'}</p>
      {url && (
        <p style={{ fontSize: '21px', textAlign: 'center' }}>
          {fullUrl}
        </p>
      )}
    </div>
  );
};

export default React.memo(Uploader);
