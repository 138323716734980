import * as querystring from 'querystring';
import { CreatePostDto } from '../dtos/posts/CreatePostDto';
import { Post } from '../models/Post';
import { apiUrl } from '../constants';
import { PaginationResponse } from '../models/PaginationResponse';
import { PublishPostsDto } from '../dtos/posts/PublishPostsDto';
import { UpdatePostDto } from '../dtos/posts/UpdatePostDto';
import { FindPostsDto } from '../dtos/posts/FindPostsDto';

export const addPost = (model: CreatePostDto, token: string): Promise<string> => {
  return fetch(`${apiUrl}/posts`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(model),
  }).then((r) => r.json() as Promise<string>);
};

export const updatePost = async (model: UpdatePostDto, token: string): Promise<void> => {
  await fetch(`${apiUrl}/posts/${model.id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(model),
  });
};

export const findAdminPosts = (input: FindPostsDto): Promise<PaginationResponse<Post>> => {
  const url = `${apiUrl}/admin/posts?${querystring.stringify(
    {
      ...input,
      startDate: input.startDate?.toDateString(),
      endDate: input.endDate?.toDateString(),
    },
  )}`;

  return fetch(url)
    .then((r) => r.json() as Promise<PaginationResponse<Post>>);
};

export const getPost = (input: string): Promise<Post> => {
  return fetch(`${apiUrl}/admin/posts/${input}`).then((r) => r.json());
};

export const publishPosts = (input: PublishPostsDto, token: string): Promise<void> => {
  return fetch(`${apiUrl}/posts/publish`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(input),
  }).then((r) => r.json() as Promise<void>);
};

export const unpublishPosts = (input: PublishPostsDto, token: string): Promise<void> => {
  return fetch(`${apiUrl}/posts/unpublish`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(input),
  }).then((r) => r.json() as Promise<void>);
};

export const removePosts = (input: PublishPostsDto, token: string): Promise<void> => {
  return fetch(`${apiUrl}/posts/remove`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(input),
  }).then((r) => r.json() as Promise<void>);
};
