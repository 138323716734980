import React from 'react';
import './Header.scss';
import HeaderProfile from '../../user/HeaderProfile/HeaderProfile';

export const Header: React.FunctionComponent = () => {
  return (
    <div className="header-container">
      <h2>Vochi Feed Admin</h2>
      <div className="header-actions">
        <HeaderProfile />
      </div>
    </div>
  );
};
