import React from 'react';
import { DefaultButton, PrimaryButton } from '@uifabric/experiments';
import './HeaderProfile.scss';
import { Auth } from '@aws-amplify/auth';
import { useAuthProvider } from '../../../providers/authProvider';

const HeaderProfile = () => {
  const { isLoading, user, isAuthorized } = useAuthProvider();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isAuthorized) {
    return (
      <div className="header-profile">
        <p className="email">{user?.signInUserSession.idToken.payload.email}</p>
        <span><DefaultButton onClick={() => Auth.signOut()}>Log out</DefaultButton></span>
      </div>

    );
  }
  return <PrimaryButton onClick={() => Auth.federatedSignIn()}>Sign in</PrimaryButton>;
};

export default React.memo(HeaderProfile);
