import React, { useEffect, useRef } from 'react';
import { IconButton } from '@fluentui/react';
import './MediaPreview.scss';
import { isImage } from '../../../core/helper';

export interface ImagePreviewProps {
  readonly previewUrl: string;
  readonly onClose: () => void;
  readonly width?: string;
  readonly changeDimensions: (width: number, height: number) => void;
}

export const MediaPreview = ({
  previewUrl, onClose, width = '335px', changeDimensions,
}: ImagePreviewProps) => {
  const ref = useRef<HTMLImageElement>(null);
  const refVideo = useRef<HTMLVideoElement>(null);
  const handleEvent = useRef(() => changeDimensions(ref.current!.naturalWidth, ref.current!.naturalHeight));
  const handleVideoEvent = useRef(() => changeDimensions(refVideo.current!.videoWidth, refVideo.current!.videoHeight));

  useEffect(() => {
    const element = ref.current;
    const handle = handleEvent.current;

    const videoElement = refVideo.current;
    const handleVideo = handleVideoEvent.current;

    if (element) {
      element.addEventListener(
        'load',
        handle,
      );
    }

    if (videoElement) {
      videoElement.addEventListener(
        'loadedmetadata',
        handleVideo,
      );
    }

    return () => {
      if (element) element.removeEventListener('load', handle);
      if (videoElement) videoElement.removeEventListener('loadedmetadata', handleVideo);
    };
  });

  const videoPreview = !isImage(previewUrl);
  return (
    <div className="preview-image">
      <IconButton onClick={onClose} title="Close" iconProps={{ iconName: 'ChromeClose' }} className="close-button" />
      {videoPreview
        // eslint-disable-next-line jsx-a11y/media-has-caption
        ? <video ref={refVideo} src={previewUrl} width={width} controls />
        : <img ref={ref} src={previewUrl} width={width} alt="preview" />}
    </div>
  );
};
