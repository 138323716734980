import React, { PropsWithChildren } from 'react';
import { Route } from 'react-router-dom';
import { useAuthProvider } from '../../../providers/authProvider';

const PrivateRoute = ({ children, ...rest }: PropsWithChildren<any>) => {
  const { isAuthorized, user } = useAuthProvider();

  if (process.env.NODE_ENV !== 'development') {
    if (!isAuthorized) {
      return <h1>You are not authorized.</h1>;
    }

    if (!(user?.signInUserSession?.accessToken?.payload['cognito:groups'] as string[])
      .some((x) => x === 'vochi-member')) {
      return <h1>You don`t have permissions.</h1>;
    }
  }

  return (
    <Route {...rest}>
      {children}
    </Route>
  );
};

export default PrivateRoute;
