import { ImageMediaItem } from './Image';
import { VideoMediaItem } from './Video';
import { MediaItemDto } from '../dtos/media/MediaItemDto';

export type MediaItem = ImageMediaItem | VideoMediaItem;

export const mapMedia = (value: MediaItem): MediaItemDto => {
  if (value.type === 'image_media') {
    const media = value as ImageMediaItem;
    return { ...media, image: { type: 'image_media', ...media.image } };
  }

  const media = value as VideoMediaItem;
  return { ...media, video: { type: 'video_media', ...media.video } };
};
