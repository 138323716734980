import { FontIcon } from '@fluentui/react';
import React from 'react';
import './MediaUploadButton.scss';

export interface MediaUploadButtonProps {
  readonly changeValue: (file: File) => void;
}

export const MediaUploadButton = ({ changeValue }: MediaUploadButtonProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length) {
      changeValue(
        e.target.files[0],
      );
    }
  };

  return (
    <div className="upload-button">
      <div className="file-upload">
        <input type="file" onChange={handleChange} accept="image/*,video/*" />
        <FontIcon iconName="PhotoVideoMedia" />
      </div>
    </div>
  );
};
