import React from 'react';
import './Hashtags.scss';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { AppApiStatus, useApi } from '../../core/services/api';
import { getHashtags } from '../../core/services/hashtagService';
import { HashtagsList } from '../../features/hashtags/HashtagsList/HashtagsList';

const items: IBreadcrumbItem[] = [
  { text: 'Hashtags', key: '/hashtags', isCurrentItem: true },
];

export default function Hashtags() {
  const { service } = useApi(getHashtags);

  return (
    <div className="hashtags-page">
      <Breadcrumb
        items={items}
        maxDisplayedItems={5}
      />
      <HashtagsList items={service.status === AppApiStatus.Loaded ? service.payload : null} />
    </div>
  );
}
