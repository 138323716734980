import React, { useState } from 'react';
import {
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  IButtonProps,
  IColumn,
  ICommandBarItemProps,
  IGroup,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
} from '@fluentui/react';
import { Link, useHistory } from 'react-router-dom';
import { Pagination } from '@uifabric/experiments';
import { PostAddRoute } from '../../../pages/Posts/AddPost/AddPost';
import { Post } from '../../../core/models/Post';
import { PostStatus } from '../../../core/models/PostStatus';
import './PostList.scss';
import { PostEditRoute } from '../../../pages/Posts/EditPost/EditPost';
import PostsFilter, { PostsFilterModel } from '../PostsFilter/PostsFilter';

const overflowButtonProps: IButtonProps = {
  ariaLabel: 'More commands',
};

export interface PostsListProps {
  readonly items?: Post[];
  readonly page: number;
  readonly totalPages: number;
  readonly onPageChange: (page: number) => void;
  readonly onPublish: (data: string[]) => void;
  readonly onUnpublish: (data: string[]) => void;
  readonly onRemove: (data: string[]) => void;
  readonly loading?: boolean;
  readonly filter: PostsFilterModel;
  readonly onFilterChange: (x: PostsFilterModel) => void;
}

const columns: IColumn[] = [
  {
    key: 'id',
    name: 'Id',
    fieldName: 'id',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: Post) => <Link style={{ color: '#0078d4' }} to={`${PostEditRoute}/${item.id}`}>{item.id}</Link>,
  },
  {
    key: 'slug',
    name: 'Slug',
    fieldName: 'slug',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'locale',
    name: 'Locale',
    onRender: (item: Post) => (item.meta.locale === '' ? 'default' : item.meta.locale),
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 30,
    maxWidth: 60,
    isResizable: true,
    onRender: (item: Post) => (item.status === PostStatus.Preview ? 'Preview' : 'Published'),
  },
];

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Remove posts',
  closeButtonAriaLabel: 'Close',
  subText: 'Do you want to remove posts?',
};

export const PostsList = ({
  items, onPublish, loading, onRemove, onPageChange, page, totalPages, onUnpublish, filter, onFilterChange,
}: PostsListProps) => {
  const history = useHistory();
  const [hideDialog, setHideDialog] = useState(true);
  const [selection] = useState(new Selection());

  const menuItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'Add',
      iconProps: { iconName: 'Add' },
      ariaLabel: 'New',
      onClick: () => history.push(PostAddRoute),
    },
    {
      key: 'publish',
      text: 'Publish',
      iconProps: { iconName: 'PublishContent' },
      ariaLabel: 'Publish',
      onClick: () => onPublish(selection.getSelection().map((x) => (x as Post).id)),
    },
    {
      key: 'unpublish',
      text: 'Unpublish',
      iconProps: { iconName: 'UnpublishContent' },
      ariaLabel: 'Unpublish',
      onClick: () => onUnpublish(selection.getSelection().map((x) => (x as Post).id)),
    },
    {
      key: 'remove',
      text: 'Remove',
      iconProps: { iconName: 'Delete' },
      ariaLabel: 'Remove',
      onClick: () => setHideDialog(false),
    },
  ];

  const groups: IGroup[] | undefined = items?.reduce(
    (prevValue: IGroup[], value, index) => {
      if (!prevValue.length) {
        return [{
          key: value.slug, name: value.slug, startIndex: index, count: 1, level: 0,
        } as IGroup];
      }

      if (prevValue[prevValue.length - 1].key === value.slug) {
        return prevValue.map((x, i) => (i === prevValue.length - 1 ? { ...x, count: x.count + 1 } : x));
      }

      return [...prevValue, {
        key: value.slug, name: value.slug, startIndex: index, count: 1, level: 0,
      } as IGroup];
    },
    [],
  );

  const remove = () => {
    setHideDialog(true);
    onRemove(selection.getSelection().map((x) => (x as Post).id));
  };

  return (
    <>
      <div style={{ paddingBottom: '1rem' }}>
        <PostsFilter value={filter} onChange={onFilterChange} />
      </div>
      <CommandBar
        overflowButtonProps={overflowButtonProps}
        items={menuItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      <Dialog
        hidden={hideDialog}
        onDismiss={() => setHideDialog(true)}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={remove} text="Remove" />
          <DefaultButton onClick={() => setHideDialog(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <ShimmeredDetailsList
        selection={selection}
        selectionMode={SelectionMode.multiple}
        groups={groups}
        items={items ?? []}
        columns={columns}
        enableShimmer={!items || loading}
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
      />
      <div className="post-pagination">
        <Pagination
          styles={{ pageNumber: { marginBottom: '.5rem' } }}
          format="buttons"
          pageCount={totalPages}
          selectedPageIndex={page}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};
