import { INavLinkGroup, INavStyles, Nav } from '@fluentui/react';
import React from 'react';
import { withRouter } from 'react-router-dom';

const navStyles: Partial<INavStyles> = {
  root: {
    width: '12rem',
  },
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Home',
        url: '/',
        key: '/',
        isExpanded: true,
        target: '_blank',
      },
      {
        name: 'Posts',
        url: '/posts',
        key: '/posts',
        target: '_blank',
      },
      {
        name: 'Hashtags',
        url: '/hashtags',
        key: '/hashtags',
        target: '_blank',
      },
      {
        name: 'Feedback',
        url: '/feedback',
        key: '/feedback',
        target: '_blank',
      },
      {
        name: 'Uploader',
        url: '/uploader',
        key: '/uploader',
        target: '_blank',
      },
    ],
  },
];

export const AppNav = withRouter(({ history }) => {
  return (
    <Nav
      onLinkClick={(x, i) => {
        if (x) {
          x.nativeEvent.preventDefault();
        }
        if (i && i.key) {
          history.push(i.key);
        }
      }}
      selectedKey={history.location.pathname}
      ariaLabel="Nav basic example"
      styles={navStyles}
      groups={navLinkGroups}
    />
  );
});
