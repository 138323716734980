import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useFormValidationContext } from './useFormValidationContext';
import { validate } from './useValidation';
import { FieldState, FieldStatus, ValidationRules } from './types';

export interface ControllerProps<TValue> {
  rules?: ValidationRules;
  value?: TValue;
  changeValue?: (value: TValue) => void;
  render: (props: {
    onChangeValue: (value: TValue) => Promise<void>,
    onBlur: () => Promise<void>,
    error?: string,
  }) => React.ReactNode;
}

export const Controller = <TValue, >({
  rules,
  changeValue,
  render,
  value,
}: ControllerProps<TValue>) => {
  const { addField, removeField } = useFormValidationContext();

  const [errors, setErrors] = useState<FieldState>({
    valid: FieldStatus.Processing,
    showError: false,
  });

  const errorsRef = useRef(errors);
  errorsRef.current = errors;

  const ref = useRef<HTMLDivElement | null>(null);

  const validateValue = useCallback(async (showError: boolean, v?: TValue) => {
    setErrors({
      valid: FieldStatus.Processing,
      showError,
    });

    const error = await validate(v, rules);
    setErrors({
      valid: error ? FieldStatus.NotValid : FieldStatus.Valid,
      error,
      showError,
    });
  }, [rules]);

  const onChangeValue = async (newValue: TValue) => {
    if (changeValue) changeValue(newValue);
    await validateValue(true, newValue);
  };

  const onBlur = async () => {
    await validateValue(true, value);
  };

  const focusFirstInput = (element: HTMLElement) => {
    (element.querySelector('input, textarea') as HTMLInputElement | undefined)?.focus();
  };

  useEffect(() => {
    const field = ref.current;
    if (field) {
      validateValue(false, value);
      addField(
        { ...field, focus: () => focusFirstInput(field) },
        () => errorsRef.current,
        (display) => setErrors({ ...errorsRef.current, showError: display }),
        () => {},
      );
    }

    return () => {
      if (field) {
        removeField(field);
      }
    };
  }, [addField, removeField, validateValue, value]);

  return (
    <div ref={ref}>
      {render({ onChangeValue, onBlur, error: errors.showError ? errors.error : undefined })}
    </div>
  );
};
