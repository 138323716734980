import React from 'react';
import {
  Button, DatePicker, DayOfWeek, Dropdown, IDropdownOption, TextField,
} from '@fluentui/react';
import { PostStatus } from '../../../core/models/PostStatus';
import { currentPostVersion } from '../../../core/constants';

export interface PostsFilterModel {
  readonly search?: string;
  readonly startDate?: Date;
  readonly endDate?: Date;
  readonly status?: PostStatus;
  readonly locale?: string;
  readonly version?: number;
}

export interface PostsFilterProps {
  readonly value: PostsFilterModel;
  readonly onChange: (val: PostsFilterModel) => void;
}

const options: IDropdownOption[] = [
  { key: '', text: 'Empty' },
  { key: PostStatus.Preview, text: 'Preview' },
  { key: PostStatus.Published, text: 'Published' },
];

const versionOptions: IDropdownOption[] = [
  { key: 1, text: 'Version 1' },
  { key: 2, text: 'Version 2' },
];

const PostsFilter = ({ value, onChange }: PostsFilterProps) => {
  const onVersionChange = (version: number) => {
    onChange({ ...value, version });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <TextField
          styles={{ root: { marginRight: '1rem' } }}
          label="Search"
          value={value.search ?? ''}
          onChange={(e, search) => onChange({ ...value, search })}
          placeholder="Enter keyword"
        />
        <TextField
          styles={{ root: { marginRight: '1rem' } }}
          label="Locale"
          value={value.locale ?? ''}
          onChange={(e, locale) => onChange({ ...value, locale })}
          placeholder="Enter locale"
        />
        <Dropdown
          styles={{ root: { marginRight: '1rem' } }}
          selectedKey={value.status ?? null}
          onChange={(e, opt) => onChange(
            {
              ...value,
              status: opt?.key === '' ? undefined : opt?.key as PostStatus,
            },
          )}
          placeholder="Select a status"
          label="Status"
          options={options}
        />
        <Dropdown
          placeholder="Select a version"
          label="Version"
          options={versionOptions}
          selectedKey={value.version}
          onChange={(e, v) => onVersionChange(+(v?.key ?? currentPostVersion))}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <DatePicker
          styles={{ root: { marginRight: '1rem' } }}
          value={value.startDate}
          onSelectDate={(startDate) => onChange({ ...value, startDate: startDate ?? undefined })}
          label="Start date"
          firstDayOfWeek={DayOfWeek.Monday}
          placeholder="Select a start date..."
          ariaLabel="Select a date"
        />
        <DatePicker
          styles={{ root: { marginRight: '1rem' } }}
          value={value.endDate}
          onSelectDate={(startDate) => onChange({ ...value, endDate: startDate ?? undefined })}
          label="End date"
          firstDayOfWeek={DayOfWeek.Monday}
          placeholder="Select a start date..."
          ariaLabel="Select a date"
        />
        <Button onClick={() => onChange({})}>Clear</Button>
      </div>
    </div>
  );
};

export default React.memo(PostsFilter);
