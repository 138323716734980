import React from 'react';

export type Field = HTMLInputElement | HTMLElement;

export interface FormValidation {
  handleSubmit:
  (onSubmit: () => any, validate?: () => string | undefined) => (e?: React.BaseSyntheticEvent) => Promise<void>;
  addField: (
    element: Field,
    getState: () => FieldState,
    setDisplayError: (display: boolean) => void,
    onRemoved: () => void) => void;
  removeField: (element: Field) => void;
  globalError: string | undefined;
}

export interface ValidationRules {
  required?: string,
  pattern?: {
    reg: RegExp,
    message: string
  }
  validate?: (value: any) => string | undefined;
}

// eslint-disable-next-line no-shadow
export enum FieldStatus {
  Processing,
  NotValid,
  Valid,
}

export interface FieldState {
  valid: FieldStatus;
  error?: string;
  showError: boolean;
}

export interface FormField {
  getState:() => FieldState;
  onDestroy: () => void;
  setDisplayError: (display: boolean) => void;
}
