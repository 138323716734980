import React from 'react';
import { FormValidation } from './types';

const FormValidationContext = React.createContext<FormValidation | undefined>(undefined);

FormValidationContext.displayName = 'FormValidationContext';

export const useFormValidationContext = (): FormValidation => React.useContext(FormValidationContext) as FormValidation;

export const FormValidationProvider = ({ children, ...props }: React.PropsWithChildren<FormValidation>) => (
  <FormValidationContext.Provider value={props}>
    {children}
  </FormValidationContext.Provider>
);
