import { Auth } from '@aws-amplify/auth';
import { initializeIcons } from '@uifabric/icons';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import PrivateRoute from './features/other/PrivateRoute/PrivateRoute';
import { Layout } from './features/pageStructure/Layout/Layout';
import FeedbackPage from './pages/Feedback/Feedback';
import { AddHashtag, HashtagAddRoute } from './pages/Hashtags/AddHashtag/AddHashtag';
import Hashtags from './pages/Hashtags/Hashtags';
import Home from './pages/Home';
import AddPost, { PostAddRoute } from './pages/Posts/AddPost/AddPost';
import { EditPost, PostEditRoute } from './pages/Posts/EditPost/EditPost';
import Posts from './pages/Posts/Posts';
import Uploader from './pages/Uploader/Uploader';
import { AuthProvider } from './providers/authProvider';

initializeIcons();

Auth.configure({
  userPoolId: 'eu-central-1_2HRAMKeLd',
  userPoolWebClientId: '5lt91ha6hdbd756k3v3bb6h5ua',
  oauth: {
    domain: 'vochi.auth.eu-central-1.amazoncognito.com',
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: `${window.location.origin}`,
    redirectSignOut: `${window.location.origin}`,
    region: 'eu-central-1',
    responseType: 'code',
  },
});

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute path={`${PostEditRoute}/:id`}>
              <EditPost />
            </PrivateRoute>
            <PrivateRoute path={PostAddRoute}>
              <AddPost />
            </PrivateRoute>
            <PrivateRoute path="/posts">
              <Posts />
            </PrivateRoute>
            <PrivateRoute path={HashtagAddRoute}>
              <AddHashtag />
            </PrivateRoute>
            <PrivateRoute path="/hashtags">
              <Hashtags />
            </PrivateRoute>
            <PrivateRoute path="/uploader">
              <Uploader />
            </PrivateRoute>
            <PrivateRoute path="/feedback">
              <FeedbackPage />
            </PrivateRoute>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
