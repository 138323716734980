import React, { useEffect, useState } from 'react';
import './Posts.scss';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { PostsList } from '../../features/posts/PostsList/PostsList';
import { AppApiStatus, Service, useApiCallback } from '../../core/services/api';
import {
  findAdminPosts, publishPosts, removePosts, unpublishPosts,
} from '../../core/services/postsService';
import { Post } from '../../core/models/Post';
import { PaginationResponse } from '../../core/models/PaginationResponse';
import { PublishPostsDto } from '../../core/dtos/posts/PublishPostsDto';
import { RemovePostsDto } from '../../core/dtos/posts/RemovePostsDto';
import { useAuthProvider } from '../../providers/authProvider';
import { FindPostsDto } from '../../core/dtos/posts/FindPostsDto';

const items: IBreadcrumbItem[] = [
  { text: 'Posts', key: '/posts', isCurrentItem: true },
];

export default function Posts() {
  const [filter, setFilter] = useState<FindPostsDto>({ skip: 0, take: 10 });
  const [refetch, setRefetch] = useState(false);
  const [service, setService] = useState<Service<PaginationResponse<Post>>>({
    status: AppApiStatus.Init,
  });

  const { accessToken } = useAuthProvider();

  const [publishService, sendPublishRequest] = useApiCallback<void>(publishPosts, () => setRefetch(!refetch));
  const [unpublishService, sendUnpublishRequest] = useApiCallback<void>(unpublishPosts, () => setRefetch(!refetch));
  const [removeService, sendRemoveRequest] = useApiCallback<void>(removePosts, () => setRefetch(!refetch));

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setService({ status: AppApiStatus.Loading });

      try {
        const res = await findAdminPosts(filter);
        setService({ status: AppApiStatus.Loaded, payload: res });
      } catch (error) {
        setService({ status: AppApiStatus.Error, error });
      }
    };
    fetchData();
  }, [refetch, filter]);

  return (
    <div className="posts-page">
      <Breadcrumb
        items={items}
        maxDisplayedItems={5}
      />
      <PostsList
        items={service.status === AppApiStatus.Loaded ? service.payload.items : undefined}
        onPublish={(data) => sendPublishRequest({ posts: data } as PublishPostsDto, accessToken)}
        onUnpublish={(data) => sendUnpublishRequest({ posts: data } as PublishPostsDto, accessToken)}
        onRemove={(data) => sendRemoveRequest({ posts: data } as RemovePostsDto, accessToken)}
        loading={
          unpublishService.status === AppApiStatus.Loading
          || publishService.status === AppApiStatus.Loading
          || removeService.status === AppApiStatus.Loading
        }
        onPageChange={(page) => setFilter({ ...filter, skip: page * 10 })}
        page={filter.skip / 10}
        totalPages={
          service.status === AppApiStatus.Loaded
            ? Math.ceil(service.payload.totalCount / 10)
            : filter.skip / 10 + 1
        }
        filter={filter ?? ''}
        onFilterChange={(x) => setFilter({ ...filter, ...x, skip: 0 })}
      />
    </div>
  );
}
