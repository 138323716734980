import * as React from 'react';
import isDetached from './isDetached';

export default function onDomRemove(
  fieldsRef: React.MutableRefObject<Map<Element, { onDestroy: () => void; }>>,
): MutationObserver {
  const observer = new MutationObserver((): void => {
    fieldsRef.current.forEach((value, field) => {
      if (field && isDetached(field)) {
        value.onDestroy();
      }
    });
  });

  observer.observe(window.document, {
    childList: true,
    subtree: true,
  });

  return observer;
}
