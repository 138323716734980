import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CommandBar,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  Fabric,
  IButtonProps,
  ICommandBarItemProps,
  ITextFieldStyles,
  SelectionMode,
  TextField,
} from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { Hashtag } from '../../../core/models/Hashtag';
import { HashtagAddRoute } from '../../../pages/Hashtags/AddHashtag/AddHashtag';

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: '300px' } };

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: number;
}

export interface HashtagsListProps {
  items: Hashtag[] | null;
}

const columns = [
  {
    key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true,
  },
  {
    key: 'column2', name: 'Id', fieldName: 'id', minWidth: 100, maxWidth: 200, isResizable: true,
  },
];

const overflowButtonProps: IButtonProps = {
  ariaLabel: 'More commands',
};

export const HashtagsList = ({ items }: HashtagsListProps) => {
  const history = useHistory();
  const [filtered, setFiltered] = useState<Hashtag[]>(items ?? []);

  useEffect(() => setFiltered(items ?? []), [items]);

  const commandItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'Add',
      iconProps: { iconName: 'Add' },
      ariaLabel: 'New',
      onClick: () => history.push(HashtagAddRoute),
    },
  ];

  const onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
    if (items === null) return;
    setFiltered(
      text ? items.filter((i) => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : items,
    );
  };

  return (
    <Fabric>
      <CommandBar
        overflowButtonProps={overflowButtonProps}
        items={commandItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      <TextField
        label="Filter by name:"
        onChange={onFilter}
        styles={textFieldStyles}
      />
      <ShimmeredDetailsList
        selectionMode={SelectionMode.none}
        items={filtered}
        columns={columns}
        enableShimmer={!items}
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
      />
    </Fabric>
  );
};
